@import "../../scss/variables";

.sidenav .MuiListItem-gutters {
  padding-left: 0;
  padding-right: 0;
  padding-inline-start: 16px;
  padding-inline-end: 0;
}
.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: white;
  overflow-x: hidden;
  padding-top: 20px;
  transition: 0.5s;
  box-shadow: 0px 2px 11px 0px rgba(147, 147, 147, 0.2);
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .MuiList-root {
    margin-top: 5.5em;
  }

  .MuiListItemIcon-root {
    min-width: 30px !important;
    color: $primaryColor;
  }
  .MuiTypography-body1 {
    color: $primaryColor !important;
    font-size: 13px !important;
  }

  .listItem {
    width: 90%;
    margin: 0 auto;
    &:hover,
    &.Mui-selected,
    &.Mui-selected:hover {
      border: 0 !important;
      background: $secondaryColor !important;
      color: $primaryColor;
      border-radius: 5px;
      box-shadow: 0px 1px 17px 0px rgba(127, 50, 63, 0.22);
      .MuiTypography-body1 {
        color: white !important;
      }
    }
  }

  .curvedNav {
    position: fixed;
    left: 16em;
    right: auto;
    top: 5em;
    transition: 0.5s;
    display: inline-block;
    .openBtn {
      display: none;
      left: 0.4em;
      position: absolute;
      top: 2.2em;
      z-index: 999;
      background-color: $transparentColor;
      height: 38px;
      width: 20px;
      padding: 0;
      text-align: center;
      transition: 0.9s;
      border-radius: 90px 0 0 90px;
      .MuiSvgIcon-root {
        margin-left: 0.2em;
        color: $primaryColor;
      }
    }
    .closebtn {
      left: 0.4em;
      position: absolute;
      top: 2.2em;
      z-index: 999;
      background-color: $transparentColor;
      height: 38px;
      width: 20px;
      padding: 0;
      text-align: center;
      transition: 0.5s;
      border-radius: 90px 0 0 90px;
      .MuiSvgIcon-root {
        margin-left: 0.3em;
        color: $primaryColor;
      }
    }
  }

  .logoSideNav {
    height: 4em;
    margin-top: 3em;
    position: absolute;
    bottom: 0;
  }
  .sideMenuItemList {
    min-height: 60vh;
    max-height: 60vh;
    overflow: auto;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
}

.my_interviews_icon {
  height: 1.6em;
}

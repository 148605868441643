.rtl {
  text-align: right;
  font-family: NeoSansArabic;
  .bold_font {
    font-family: NeoSansArabicBold;
  }

  .medium_font,
  .semi_bold_font {
    font-family: NeoSansArabicMedium;
  }

  .regular_font {
    font-family: NeoSansArabic;
  }
  .MuiTableCell-body {
    font-family: NeoSansArabic;
  }

  .requistionDescription .MuiFormHelperText-root {
    text-align: left;
  }

  .contract-tabs-container{
    position: relative;
    .nav-link.active {
      background-color: white;
      color: $primaryColor;
      font-weight: 500;
      &::before{
        content: "";
        right: 0;
        left: auto;
        height: 20px;
        position: absolute;
        border-left: 2px solid $primaryColor ;
      }
    }
  }

  .auth_container {
    margin-right: auto !important;
    margin-left: 0em !important;
    .slider_container {
      padding-left: 0;
      position: relative;
      &::before {
        border-radius: 0px 14px 14px 0px;
        left: 0;
        right: auto;
      }
      &::after {
        left: 0;
        right: auto;
        transform: rotateY(180deg);
      }

      .loginImg {
        right: 1em;
        left: auto;
      }
    }

    .langBtnAlign {
      text-align: left;
    }
  }

  .country-list {
    direction: rtl;
    text-align: right;
  }

  .react-tel-input {
    position: relative;
    &::before {
      content: "*رقم الجوال";
      position: absolute;
      top: -8px;
      right: 19px;
      right: 10px;
      display: block;
      background: white !important;
      padding: 0 5px;
      font-size: 0.8rem;
      width: 56px;
      z-index: 999;
      color: #6b6b6b;
    }
    .form-control + div:before {
      display: none;
    }
  }

  .sidenav {
    right: 0 !important;
    left: auto !important;
    .curvedNav {
      right: 16em;
      left: auto;
      top: 12em;
      transform: rotate(180deg);
      transition: 0.5s;
      .openBtn {
        .MuiSvgIcon-root {
          margin-right: -0.2em;
        }
      }
      .closebtn {
        .MuiSvgIcon-root {
          margin-right: -0.3em;
        }
      }
    }
  }

  .navbar .dropdown:hover > .dropdown-menu {
    right: 0 !important;
    text-align: right !important;
  }

  .help-tip {
    left: 25px;
    right: auto;
    &:hover ul {
      text-align: right !important;
      left: 0 !important;
      right: auto !important;
    }
  }
  .MuiButton-root {
    font-family: NeoSansArabicMedium !important;
  }

  #alert-dialog-description {
    font-family: NeoSansArabic !important;
  }
}

.MuiDialog-paper[dir="rtl"] {
  text-align: right;
}

.MuiMenuItem-root[dir="rtl"] {
  font-family: NeoSansArabic !important;
}

.MuiDialog-root.text-right{
  .MuiDialogTitle-root{
      h2.MuiTypography-h6{
          .bold_font{
              font-family: NeoSansArabicBold;
          }
      }
  }
  .regular_font{
      font-family: NeoSansArabic;
      font-size: 14px;
  }
  .bold_font{
      font-family: NeoSansArabicBold;
      font-size: 14px;
  }
  .medium_font{
      font-family: NeoSansArabicMedium;
      font-size: 14px;
  }

  .MuiButton-label {
      font-family: NeoSansArabic;
  }
}

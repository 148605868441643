@import '../scss/base.scss';

.loading-indicator:before {
    content: '';
    background: #000000cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999999999999;
}

.loading-indicator:after {
    content: "\f1ce";
    font-family: FontAwesome;
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 99999999999999;
    color:white;
    text-align:center;
    font-weight:100;
    font-size:4rem;       
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear; 
}


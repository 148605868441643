@import "../../scss/variables";
.drawerShown {
  display: none !important;
}
.listItem {
  width: 90% !important;
  margin: 0 auto !important;
  &:hover,
  &.Mui-selected,
  &.Mui-selected:hover {
    border: 0 !important;
    background: $secondaryColor !important;
    color: $primaryColor;
    border-radius: 5px;
    box-shadow: 0px 1px 17px 0px rgba(127, 50, 63, 0.22);
    .MuiTypography-body1 {
      color: white !important;
    }
  }
}

.logoSideNav {
  height: 4em;
  margin-top: 3em;
  position: absolute;
  bottom: 0;
}


@media screen and (max-width: 900px) {
  .sidenav {
    display: none !important;
  }

  .drawerShown {
    display: block !important;
    .MuiSvgIcon-root{
      fill: white;
    }
  }

  .navbar {
    margin: 0 !important;
  }

  #main {
    margin-left: 40px;
    margin-right: 40px;
  }
}

.my_interviews_icon {
  height: 1.6em;
}

/* ============= Root ============= */
:root {
  --primary-color: #808080;
  --secondary-color: #808080;
  --default-primary-color: #86364e;
  --default-secondary-color: #ea8685;
  --transparent-color: rgba(172, 69, 86, 0.06);
  --light-text-color: #7a7a7a;
  --secondary-color-transparent: rgb(128, 128, 128, .2);
}
/* ============= Colors ============= */
$primaryColor: var(--primary-color);
$secondaryColor: var(--secondary-color);
$defaultPrimaryColor: var(--default-primary-color);
$defaultSecondaryColor: var(--default-secondary-color);
$darkPrimaryColor: rgba(63, 23, 30, 1);
$darkTextColor: #3e3e3e;
$lightTextColor: #7a7a7a;
$transparentColor: var(--transparent-color);
$darkColorText: rgba(62, 62, 62, 1);
$lightGrey: rgba(219, 219, 219, 1);
$lightBorder: rgba(224, 233, 239, 1);
$colorLight: rgba(234, 134, 133, 0.1);
$error: #ff3739;
$success: #3bd35f;
$modalPaperColor: #f2f2f2;
/* ============= Fonts ============= */
$fontExtentions: "eot", "ttf", "woff", "woff2";
$fontFamilies: "Montserrat-Bold", "Montserrat-Medium", "Montserrat-Regular",
  "Montserrat-SemiBold", "Montserrat-Black";
$ArabicFontFamilies: "NeoSansArabic", "NeoSansArabicBold", "NeoSansArabicMedium";
//fae2e2  [#F6BAC2, #B77983 in delete icon]
//review copyTemplateModal onclick icondelete
//subscription plans
